window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});



(function() {
var tf = document.createElement('script');
tf.type = 'text/javascript'; tf.async = true;
tf.src = ("https:" == document.location.protocol ? 'https' : 'http') + "://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=" + new Date().getTime() + Math.random();
var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);
})();




